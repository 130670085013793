:root {
  --font-family: "WorkSans", sans-serif;
  --content-width: 1330px;
  --content-fluid-width: 1300px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));
  --light-color: #ffffff;
  --dark-color: #000000;
  --btn-color: #3A55F7;
  --btn-hover: #1937EF;
  --btn-white-hover: #e7eaff;
  --dark-bg: #1B1B1B;
  --green-color: #20A354;
  --accent-color: #A3FFDE;
  --green-color-accent: #55D086;
}

/* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansMediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  color: var(--dark-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

html {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

b, strong {
  font-weight: 700;
}

a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}

a, li, button {
  outline: none !important;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  font-family: "Inter";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 18px 25px;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  min-width: 336px;
  min-height: 67px;
  color: var(--light-color);
  border-radius: 15px;
  background-color: var(--btn-color);
  transition: all 0.3s ease;
}
.btn:hover, .btn:active, .btn:focus {
  color: var(--light-color);
  background-color: var(--btn-hover);
}
@media (max-width: 768px) {
  .btn {
    font-size: 16px;
    line-height: 130%;
    min-height: 56px;
  }
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
    padding: 18px 25px;
    min-width: 100%;
  }
}
.btn__white {
  background-color: var(--light-color);
  color: var(--btn-color);
}
.btn__white:hover {
  background-color: var(--btn-white-hover);
  color: var(--btn-color);
}

.btn__header {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--dark-color);
  border: 1px solid var(--dark-color);
  border-radius: 8px;
  padding: 6px 8px;
  margin-left: auto;
  margin-right: 20px;
}
.btn__header:hover, .btn__header:active, .btn__header:focus {
  color: var(--light-color);
  background-color: var(--btn-color);
  border: 1px solid var(--btn-color);
}
@media (max-width: 576px) {
  .btn__header {
    display: none;
  }
}

.form__btn {
  padding: 16px 25px;
  max-height: 52px;
  max-width: 336px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .form__btn {
    width: 100%;
  }
}

.btn__center {
  text-align: center;
}

.slide__btn {
  stroke: var(--dark-color);
}
@media (max-width: 576px) {
  .slide__btn {
    stroke: var(--light-color);
    height: 23px;
  }
}

.title {
  font-weight: 600;
  font-size: 64px;
  line-height: 111.8%;
}
.title-white {
  color: var(--light-color);
}
.title span {
  color: var(--green-color-accent);
}
@media (max-width: 768px) {
  .title {
    font-size: 36px;
    line-height: 56px;
    line-height: 111.8%;
  }
}

.header {
  padding: 20px 0;
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .header {
    top: 40px;
  }
}

.logo {
  display: inline-block;
}
@media (max-width: 768px) {
  .logo {
    margin-right: auto;
    width: 146px;
  }
}

.dropbtn {
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
  color: var(--light-color);
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 30px;
  background-color: var(--light-color);
  width: 80px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px;
  left: -25px;
}

.dropdown-content.active {
  display: block;
}

.dropbtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 20px 14px 25px;
}

.dropbtn::after {
  background: rgba(0, 0, 0, 0) url("../img/arrow.svg") no-repeat scroll center center;
  content: "";
  height: 5px;
  position: absolute;
  right: 0;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.dropbtn-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropdown-link {
  margin-bottom: 10px;
}
.dropdown-link:last-child {
  margin-bottom: 0;
}
.dropdown-link:hover {
  color: var(--dark-color);
}

.dropdown-link-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 23px 16px;
}

/* Links inside the dropdown */
.dropdown-link {
  padding-left: 30px;
  text-decoration: none;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-color);
}

.intro__wrap {
  padding-top: 35px;
  position: relative;
}
.intro__text {
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-bottom: 50px;
}
.intro__text span {
  font-weight: 800;
  color: var(--btn-color);
}
@media (max-width: 768px) {
  .intro__text {
    font-size: 20px;
    margin-bottom: 295px;
  }
}
.intro__title {
  font-weight: 700;
  font-size: 80px;
  line-height: 111.5%;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}
.intro__title span {
  color: var(--btn-color);
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .intro__title {
    font-weight: 600;
    font-size: 48px;
    line-height: 106.3%;
    margin-bottom: 10px;
  }
}
.intro__img {
  width: 1047px;
  height: 727px;
  background-image: url(../img/intro.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transform: translateX(112px);
  margin-left: auto;
  margin-top: -335px;
  z-index: -1;
}
@media (max-width: 1200px) {
  .intro__img {
    transform: translateX(90px);
    width: 650px;
  }
}
@media (max-width: 850px) {
  .intro__img {
    transform: translateX(0px);
    width: 400px;
    height: 447px;
    margin-top: -157px;
  }
}
@media (max-width: 768px) {
  .intro__img {
    position: absolute;
    margin-top: 0;
    height: 315px;
    top: 175px;
    left: 0;
  }
}
@media (max-width: 576px) {
  .intro__btn {
    text-align: center;
  }
}

.bonus {
  background: var(--btn-color);
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .bonus {
    padding-top: 80px;
    background: linear-gradient(0deg, #3A55F7 84%, rgba(58, 85, 247, 0) 100%);
    margin-top: -20px;
  }
}
.bonus__top {
  display: grid;
  gap: 109px;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(346px, 1fr));
  margin-bottom: 90px;
}
@media (max-width: 768px) {
  .bonus__top {
    gap: 60px;
  }
}
@media (max-width: 576px) {
  .bonus__top {
    margin-bottom: 60px;
  }
}
.bonus__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(../img/bonus__item-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  backdrop-filter: blur(8px);
  min-height: 305px;
  margin-top: -80px;
  padding: 24px 25px 8px;
  background-clip: padding-box;
  border: solid 3px transparent;
  border-radius: 40px;
}
@media (max-width: 768px) {
  .bonus__item {
    margin-top: 0px;
  }
}
@media (max-width: 576px) {
  .bonus__item {
    min-height: 250px;
    padding: 36px 25px 25px;
  }
}
.bonus__item-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--light-color);
  margin-bottom: 12px;
  text-align: center;
}
@media (max-width: 576px) {
  .bonus__item-title {
    font-size: 20px;
    margin-bottom: 7px;
  }
}
.bonus__item-text {
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--light-color);
}
.bonus__item-text span {
  color: var(--accent-color);
  font-weight: 600;
}
@media (max-width: 576px) {
  .bonus__item-text {
    font-size: 16px;
  }
}
.bonus__img {
  margin-bottom: 12px;
}
@media (max-width: 576px) {
  .bonus__img {
    margin-bottom: 25px;
    height: 85px;
  }
  .bonus__img img {
    height: 100%;
    width: 100%;
  }
}
.bonus__title {
  font-weight: 600;
  font-size: 64px;
  line-height: 111.8%;
  text-align: center;
  max-width: 868px;
  margin: 0 auto;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: var(--light-color);
}
.bonus__title span {
  color: var(--accent-color);
}
@media (max-width: 768px) {
  .bonus__title {
    font-size: 36px;
    line-height: 111.8%;
    margin-bottom: 20px;
  }
}
.bonus__text {
  max-width: 523px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
  color: var(--light-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
}
@media (max-width: 768px) {
  .bonus__text {
    font-size: 20px;
    line-height: 130%;
  }
}
@media (max-width: 576px) {
  .bonus__text {
    margin-bottom: 20px;
  }
}
.bonus__btn {
  text-align: center;
  padding: 73px 0 60px;
  background-image: url(../img/bonus__btn-desk.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
}
@media (max-width: 576px) {
  .bonus__btn {
    padding: 45px 0 45px;
    background-image: url(../img/bonus__btn-mob.svg);
  }
}

.stages {
  padding: 87px 0 140px;
  position: relative;
}
@media (max-width: 768px) {
  .stages {
    padding: 60px 0 0px;
  }
}
.stages::after {
  position: absolute;
  content: "";
  top: 110px;
  right: calc((100vw - 1300px) / 2);
  width: 612px;
  height: 334px;
  background-image: url(../img/stages__line.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@media (max-width: 1200px) {
  .stages::after {
    transform: translateX(150px);
  }
}
@media (max-width: 992px) {
  .stages::after {
    width: 400px;
    transform: translateX(50px);
  }
}
@media (max-width: 768px) {
  .stages::after {
    top: 107px;
    right: calc((100vw - 850px) / 2);
    height: 191px;
  }
}
@media (max-width: 576px) {
  .stages::after {
    top: 185px;
    right: calc((100vw - 564px) / 2);
    height: 85px;
  }
}
.stages__number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 80px;
  line-height: 111.8%;
  background: linear-gradient(#fff 0 0) padding-box, linear-gradient(-232deg, #55D086, #3A55F7) border-box;
  color: #313149;
  padding: 10px;
  border: 8px solid transparent;
  border-radius: 100px;
  min-width: 107px;
  height: 107px;
  color: var(--btn-color);
  margin-right: 20px;
}
@media (max-width: 768px) {
  .stages__number {
    min-width: 71px;
    height: 71px;
    font-size: 45px;
  }
}
.stages__wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 75px 90px;
  max-width: 1165px;
}
@media (max-width: 850px) {
  .stages__wrap {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
.stages__text {
  font-weight: 500;
  font-size: 32px;
  line-height: 111.8%;
  padding-top: 25px;
}
.stages__text span {
  font-weight: 700;
}
@media (max-width: 768px) {
  .stages__text {
    font-size: 20px;
    padding-top: 10px;
  }
}
.stages__item {
  display: flex;
}
.stages .title {
  margin-bottom: 65px;
  max-width: 770px;
}
@media (max-width: 768px) {
  .stages .title {
    margin-bottom: 40px;
  }
}

.trade {
  position: relative;
  padding: 60px 0 60px;
}
.trade__wrap {
  position: relative;
  padding-bottom: 425px;
}
.trade::after {
  position: absolute;
  content: "";
  top: 0;
  right: calc((100vw - 1470px) / 2);
  width: 1440px;
  height: 661px;
  background-image: url(../img/trade__img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  z-index: -1;
}
@media (max-width: 992px) {
  .trade::after {
    width: 100%;
    left: 0;
    right: 0;
    height: 400px;
    background-size: contain;
    bottom: 40px;
    top: auto;
  }
}
@media (max-width: 576px) {
  .trade::after {
    width: 250%;
    left: 5%;
    transform: translateX(-50%);
  }
}
.trade .title {
  margin-bottom: 20px;
}
.trade__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  max-width: 477px;
}
.trade__text span {
  font-weight: 600;
}
.trade__item {
  position: absolute;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: var(--light-color);
  background: linear-gradient(180deg, #A3FFDE 0%, #A3FFDE 0.01%, #55D086 44.27%);
  border-radius: 100px;
  padding: 17px 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .trade__item {
    font-size: 12px;
    padding: 11px 9px;
  }
}
.trade__item-1 {
  right: 0;
  top: 0;
}
@media (max-width: 992px) {
  .trade__item-1 {
    top: 200px;
  }
}
@media (max-width: 768px) {
  .trade__item-1 {
    top: 300px;
  }
}
@media (max-width: 576px) {
  .trade__item-1 {
    top: auto;
    bottom: 16px;
    right: 20px;
  }
}
.trade__item-2 {
  right: 510px;
  top: 160px;
  padding: 8px 20px;
}
@media (max-width: 1200px) {
  .trade__item-2 {
    right: 400px;
    top: 200px;
  }
}
@media (max-width: 576px) {
  .trade__item-2 {
    top: auto;
    bottom: -25px;
    right: auto;
    left: 22px;
  }
}
.trade__item-3 {
  left: 250px;
  top: 300px;
  padding: 8px 20px;
}
@media (max-width: 768px) {
  .trade__item-3 {
    top: 190px;
    left: auto;
    right: 2px;
  }
}
@media (max-width: 576px) {
  .trade__item-3 {
    top: 300px;
  }
}
.trade__item-4 {
  bottom: 160px;
  left: 0px;
}
@media (max-width: 768px) {
  .trade__item-4 {
    left: 10px;
    bottom: 200px;
  }
}
@media (max-width: 576px) {
  .trade__item-4 {
    max-width: 135px;
  }
}
.trade__item-5 {
  bottom: 80px;
  right: 80px;
}
@media (max-width: 576px) {
  .trade__item-5 {
    right: 10px;
    bottom: 125px;
  }
}

.awards {
  background-color: var(--dark-bg);
  padding-top: 60px;
  background-image: url(../img/footer__bg.svg);
  background-repeat: no-repeat;
  background-size: 1660px;
  background-position: bottom -256px center;
}
.awards .title {
  text-align: center;
  color: var(--light-color);
  margin-bottom: 55px;
}
@media (max-width: 576px) {
  .awards .title {
    margin-bottom: 45px;
  }
}
.awards__img {
  display: block;
  text-align: center;
}
.awards__img img {
  margin: 0 auto;
  max-width: initial;
}
.awards .title-white {
  margin-bottom: 25px;
}
.awards__wrap {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 130px;
}
@media (max-width: 576px) {
  .awards__wrap {
    margin-bottom: 100px;
  }
}
.awards__wrap::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
.awards__wrap::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: transparent;
}
.awards__wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0;
  background-color: transparent;
}

.form {
  position: relative;
  border-radius: 40px;
  background-image: url(../img/form__bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  backdrop-filter: blur(8px);
  max-width: 835px;
  margin: 0 auto;
  margin-bottom: 160px;
  text-align: center;
  padding: 45px 23px;
}
@media (max-width: 768px) {
  .form {
    background-image: url(../img/form__bg-mob.png);
    background-size: cover;
    padding-top: 60px;
  }
}
@media (max-width: 576px) {
  .form {
    margin-bottom: 100px;
  }
}
.form::after {
  position: absolute;
  content: "";
  left: -420px;
  bottom: 41px;
  background-image: url(../img/footer__line-left.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 133px;
  width: 497px;
}
@media (max-width: 768px) {
  .form::after {
    height: 70px;
    top: 56px;
    left: -289px;
  }
}
@media (max-width: 576px) {
  .form::after {
    top: 77px;
    left: -355px;
  }
}
.form::before {
  position: absolute;
  content: "";
  right: -391px;
  bottom: 100px;
  background-image: url(../img/footer__line-right.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 89px;
  width: 520px;
}
@media (max-width: 768px) {
  .form::before {
    height: 45px;
    top: 35px;
    right: -250px;
  }
}
@media (max-width: 576px) {
  .form::before {
    top: 16px;
    right: -297px;
  }
}
.form__wrap {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  max-width: 465px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .form__wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.form__label {
  position: relative;
  width: 100%;
}
@media (max-width: 768px) {
  .form__label {
    margin-right: 0;
  }
}
.form__input {
  width: 100%;
  height: 52px;
  background: var(--light-color);
  border-radius: 15px;
  color: var(--dark-color);
  padding: 0 20px;
}
.form__input::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #A0A0A0;
}

.footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  padding-bottom: 50px;
}
.footer__wrap {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .footer__wrap {
    flex-direction: column-reverse;
  }
}
@media (max-width: 576px) {
  .footer__link {
    margin-bottom: 15px;
    text-align: center;
  }
}

.copyright {
  color: var(--dark-color);
}
@media (max-width: 576px) {
  .copyright {
    text-align: center;
  }
}

.link {
  color: var(--dark-color);
  margin-right: 40px;
}
.link:last-child {
  margin-right: 0;
}