// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "WorkSans", sans-serif;
  --content-width: 1330px;
  --content-fluid-width: 1300px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #ffffff;
  --dark-color: #000000;
  --btn-color: #3A55F7;
  --btn-hover: #1937EF;
  --btn-white-hover: #e7eaff;
  --dark-bg: #1B1B1B;

  --green-color: #20A354;
  --accent-color: #A3FFDE;
  --green-color-accent: #55D086;

}
