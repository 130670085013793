.trade {
  position: relative;
  padding: 60px 0 60px;
  &__wrap {
    position: relative;
    padding-bottom: 425px;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    right: calc((100vw - 1470px) / 2);
    width: 1440px;
    height: 661px;
    background-image: url(../img/trade__img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: -1;
    @include high-tablet {
      width: 100%;
      left: 0;
      right: 0;
      height: 400px;
      background-size: contain;
      bottom: 40px;
      top: auto;
    }
    @include mobile {
      width: 250%;
      left: 5%;
      transform: translateX(-50%);
    }
  }
  .title {
    margin-bottom: 20px;
  }
  &__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    max-width: 477px;
    span {
      font-weight: 600;
    }
  }
  &__item {
    position: absolute;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: var(--light-color);
    background: linear-gradient(180deg, #A3FFDE 0%, #A3FFDE 0.01%, #55D086 44.27%);
    border-radius: 100px;
    padding: 17px 20px;
    text-align: center;
    @include small-tablet {
      font-size: 12px;
      padding: 11px 9px;
    }
    &-1 {
      right: 0;
      top: 0;
      @include high-tablet {
        top: 200px;
      }
      @include small-tablet {
        top: 300px;
      }
      @include mobile {
        top: auto;
        bottom: 16px;
        right: 20px;
      }

    }
    &-2 {
      right: 510px;
      top: 160px;
      padding: 8px 20px;
      @include high-desktop {
        right: 400px;
        top: 200px;
      }
      @include mobile {
        top: auto;
        bottom: -25px;
        right: auto;
        left: 22px;
      }
    }
    &-3 {
      left: 250px;
      top: 300px;
      padding: 8px 20px;
      @include small-tablet {
        top: 190px;
        left: auto;
        right: 2px;
      }
      @include mobile {
        top: 300px;
      }
    }
    &-4 {
      bottom: 160px;
      left: 0px;
      @include small-tablet {
        left: 10px;
        bottom: 200px
      }
      @include mobile {
        max-width: 135px;
      }
    }
    &-5 {
      bottom: 80px;
      right: 80px;
      @include mobile {
        right: 10px;
        bottom: 125px;
      }
    }
  }
}

