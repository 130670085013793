.stages {
  padding: 87px 0 140px;
  position: relative;
  @include small-tablet {
    padding: 60px 0 0px;
  }
  &::after {
    position: absolute;
    content: "";
    top: 110px;
    right: calc((100vw - 1300px) / 2);
    width: 612px;
    height: 334px;
    background-image: url(../img/stages__line.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    @include high-desktop  {
      transform: translateX(150px);
    }
    @include high-tablet {
      width: 400px;
      transform: translateX(50px);
    }
    @include small-tablet {
      top: 107px;
      right: calc((100vw - 850px) / 2);
      height: 191px;
    }
    @include mobile {
      top: 185px;
      right: calc((100vw - 564px) / 2);
      height: 85px;
    }
  }
  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 80px;
    line-height: 111.8%;
    background:
    linear-gradient(#fff 0 0) padding-box, /*this is your grey background*/
    linear-gradient(-232deg, #55D086, #3A55F7) border-box;
    color: #313149;
    padding: 10px;
    border: 8px solid transparent;
    border-radius: 100px;
    min-width: 107px;
    height: 107px;
    color: var(--btn-color);
    margin-right: 20px;
    @include small-tablet {
      min-width: 71px;
      height: 71px;
      font-size: 45px;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 75px 90px;
    max-width: 1165px;
    @include mini-tablet {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 32px;
    line-height: 111.8%;
    padding-top: 25px;
    span {
      font-weight: 700;
    }
    @include small-tablet {
      font-size: 20px;
      padding-top: 10px;
    }
  }

  &__item {
    display: flex;
  }
  .title {
    margin-bottom: 65px;
    max-width: 770px;
    @include small-tablet {
      margin-bottom: 40px;
    }
  }
}
