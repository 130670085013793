.bonus {
  background: var(--btn-color);
  padding-bottom: 80px;
  @include small-tablet {
    padding-top: 80px;
    background: linear-gradient(0deg, #3A55F7 84%, rgba(58, 85, 247, 0) 100%);
    margin-top: -20px;
  }
  &__top {
    display: grid;
    gap: 109px;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(346px, 1fr));
    margin-bottom: 90px;
    @include small-tablet {
      gap: 60px;
    }
    @include mobile {
      margin-bottom: 60px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: url(../img/bonus__item-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    backdrop-filter: blur(8px);
    min-height: 305px;
    margin-top: -80px;
    padding: 24px 25px 8px;
    background-clip: padding-box;
    border: solid 3px transparent;
    border-radius: 40px;
    @include small-tablet {
      margin-top: 0px;
    }
    @include mobile {
      min-height: 250px;
      padding: 36px 25px 25px;
    }
  }
  &__item-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: var(--light-color);
    margin-bottom: 12px;
    text-align: center;
    @include mobile {
      font-size: 20px;
      margin-bottom: 7px;
    }

  }
  &__item-text {
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--light-color);
    span {
      color: var(--accent-color);
      font-weight: 600;
    }
    @include mobile {
      font-size: 16px;
    }
  }
  &__img {
    margin-bottom: 12px;
    @include mobile {
      margin-bottom: 25px;
      height: 85px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &__title {
    font-weight: 600;
    font-size: 64px;
    line-height: 111.8%;
    text-align: center;
    max-width: 868px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: var(--light-color);
    span {
      color: var(--accent-color);
    }
    @include small-tablet {
      font-size: 36px;
      line-height: 111.8%;
      margin-bottom: 20px;
    }
  }
  &__text {
    max-width: 523px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
    color: var(--light-color);
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    @include small-tablet {
      font-size: 20px;
      line-height: 130%;
    }
    @include mobile {
      margin-bottom: 20px;
    }
  }
  &__btn {
    text-align: center;
    padding: 73px 0 60px;
    background-image: url(../img/bonus__btn-desk.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    @include mobile {
      padding: 45px 0 45px;
      background-image: url(../img/bonus__btn-mob.svg);
    }
  }
}


// .hunt__border{
//   position:absolute;
//   top:0;
//   left:0;
//   width:100%;
//   height:100%;
//   border-radius: 16px;
//   z-index: -1;
// }

// .hunt__border rect{
//   fill: none;
//   stroke: url(#border__gradient);
//   stroke-width: 3px;
//   width: calc(100% - 4.5px);
//   height: calc(100% - 4.5px);
// }
