.awards {
  background-color: var(--dark-bg);
  padding-top: 60px;
  background-image: url(../img/footer__bg.svg);
  background-repeat: no-repeat;
  background-size: 1660px;
  background-position: bottom -256px center;
  .title {
    text-align: center;
    color: var(--light-color);
    margin-bottom: 55px;
    @include mobile {
      margin-bottom: 45px;
    }
  }
  &__img {
    display: block;
    text-align: center;
    img {
      margin: 0 auto;
      max-width: initial;
    }
  }
  .title-white {
    margin-bottom: 25px;
  }

  &__wrap {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 130px;
    @include mobile {
      margin-bottom: 100px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      background-color: transparent;
    }
  }
}

.form {
  position: relative;
  border-radius: 40px;
  background-image: url(../img/form__bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  backdrop-filter: blur(8px);
  max-width: 835px;
  margin: 0 auto;
  margin-bottom: 160px;
  text-align: center;
  padding: 45px 23px;
  @include small-tablet {
    background-image: url(../img/form__bg-mob.png);
    background-size: cover;
    padding-top: 60px;
  }
  @include mobile {
    margin-bottom: 100px;
  }

  &::after {
    position: absolute;
    content: "";
    left: -420px;
    bottom: 41px;
    background-image: url(../img/footer__line-left.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 133px;
    width: 497px;
    @include small-tablet {
      height: 70px;
      top: 56px;
      left: -289px;
    }
    @include mobile {
      top: 77px;
      left: -355px;
    }
  }
  &::before {
    position: absolute;
    content: "";
    right: -391px;
    bottom: 100px;
    background-image: url(../img/footer__line-right.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 89px;
    width: 520px;
    @include small-tablet {
      height: 45px;
      top: 35px;
      right: -250px;
    }
    @include mobile {
      top: 16px;
      right: -297px;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 465px;
    margin: 0 auto;
    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &__label {
    position: relative;
    width: 100%;
    @include small-tablet {
      margin-right: 0;
    }
  }
  &__input {
    width: 100%;
    height: 52px;
    background: var(--light-color);
    border-radius: 15px;
    color: var(--dark-color);
    padding: 0 20px;
    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      color: #A0A0A0;
    }
  }
}
