.intro {
  &__wrap {
    padding-top: 35px;
    position: relative;

  }
  &__text {
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.02em;
    margin-bottom: 50px;
    span {
      font-weight: 800;
      color: var(--btn-color);
    }
    @include small-tablet {
      font-size: 20px;
      margin-bottom: 295px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 80px;
    line-height: 111.5%;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
    span {
      color: var(--btn-color);
      text-transform: uppercase;
    }
    @include small-tablet {
      font-weight: 600;
      font-size: 48px;
      line-height: 106.3%;
      margin-bottom: 10px;
    }

  }
  &__img {
    width: 1047px;
    height: 727px;
    background-image: url(../img/intro.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transform: translateX(112px);
    margin-left: auto;
    margin-top: -335px;
    z-index: -1;

    @include high-desktop {
      transform: translateX(90px);
      width: 650px;
    }
    @include mini-tablet {
      transform: translateX(0px);
      width: 400px;
      height: 447px;
      margin-top: -157px;
    }
    @include small-tablet {
      position: absolute;
      margin-top: 0;
      height: 315px;
      top: 175px;
      left: 0;

    }

  }

  &__btn {
    @include mobile {
      text-align: center;
    }
  }

}
